<ng-template #content let-offcanvas class="nav-button">
	<div class="side-nav-container">
		<div class="offcanvas-header">
			<h4 *ngIf="!userLoggedIn else guestTemplate" class="offcanvas-title">Welcome, Guest</h4>
			<ng-template #guestTemplate>
				<h4 id="header" class="offcanvas-title">Welcome, {{username}}</h4>
			</ng-template>
			<button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
		</div>
		<div class="offcanvas-body">
			<div class="side-nav-body-content mb-3">
				<a class="mt-3 option-container" routerLink="/home" (click)="offcanvas.dismiss()">
					<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#31f5f5" class="bi bi-house margin-icons" viewBox="0 0 16 16">
						<path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z"/>
					</svg>
					Home
				</a>
				<a class="mt-3 option-container" routerLink="/about" (click)="offcanvas.dismiss()">
					<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#ffc477" class="bi bi-file-earmark-person margin-icons" viewBox="0 0 16 16">
						<path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
						<path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2v9.255S12 12 8 12s-5 1.755-5 1.755V2a1 1 0 0 1 1-1h5.5v2z"/>
					</svg>
					About
				</a>
				<a class="mt-3 option-container" routerLink="/reviews" (click)="offcanvas.dismiss()">
					<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="red" class="bi bi-hearts margin-icons" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M4.931.481c1.627-1.671 5.692 1.254 0 5.015-5.692-3.76-1.626-6.686 0-5.015Zm6.84 1.794c1.084-1.114 3.795.836 0 3.343-3.795-2.507-1.084-4.457 0-3.343ZM7.84 7.642c2.71-2.786 9.486 2.09 0 8.358-9.487-6.268-2.71-11.144 0-8.358Z"/>
					</svg>
					Reviews
				</a>
				<a class="mt-3 option-container" routerLink="/howItWorks" (click)="offcanvas.dismiss()">
					<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#3c8c2b" class="bi bi-question-lg margin-icons" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14"/>
					  </svg>
					How it works!
				</a>
				<ng-container *ngIf="userLoggedIn">
					<a class="mt-3 option-container" routerLink="/list" (click)="offcanvas.dismiss()">
						<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#c69bff" class="bi bi-card-checklist margin-icons" viewBox="0 0 16 16">
							<path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
							<path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
						</svg>
						Order
					</a>
	
					<a class="mt-3 option-container" routerLink="/previousLists" (click)="offcanvas.dismiss()">
						<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-list-check margin-icons" viewBox="0 0 16 16">
							<path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0m0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0m0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0"/>
						</svg>
						 Previous Items
					</a>
	
					<!-- <a class="mt-3 option-container" routerLink="/mealkit" (click)="offcanvas.dismiss()">
						<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#3c8c2b" class="bi bi-bag-heart-fill margin-icons" viewBox="0 0 16 16">
							<path d="M11.5 4v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m0 6.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132"/>
						  </svg>
						 Meal Delivery Kit
					</a> -->

					<!-- <a class="mt-3 option-container" routerLink="/deals" (click)="offcanvas.dismiss()">
						<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#2A246A" class="bi bi-credit-card margin-icons" viewBox="0 0 16 16">
							<path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"/>
							<path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"/>
						</svg>	
						 Weekly Deals
					</a>
					
					<a class="mt-3 option-container" routerLink="/treats" (click)="offcanvas.dismiss()">
						<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#2A246A" class="bi bi-credit-card margin-icons" viewBox="0 0 16 16">
							<path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"/>
							<path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"/>
						</svg>
						Sweet Treats
					</a> -->

					<a class="mt-3 option-container" *ngIf="showAdmin" routerLink="/admin"  (click)="offcanvas.dismiss()"> <!-- *ngIf="showAdmin" -->
						<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#31f5f5" class="bi bi-person-badge margin-icons" viewBox="0 0 16 16">
							<path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
							<path d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492z"/>
						  </svg>	
						 Admin Panel
					</a>
				</ng-container>
				
			</div>
			<!-- <div class="side-nav-body-user">
				<a class="mt-5 option-container" *ngIf="showLogin" (click)="offcanvas.dismiss(); loginClicked()">
					<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="rgb(182,136,104)" class="bi bi-door-open margin-icons" viewBox="0 0 16 16">
						<path d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z"/>
						<path d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117zM11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5zM4 1.934V15h6V1.077l-6 .857z"/>
					  </svg>
					  Login
				</a>
				<a class="mt-5 option-container" routerLink="/profile" *ngIf="showProfile()" (click)="offcanvas.dismiss()">
					<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="rgb(182,136,104)" class="bi bi-door-open margin-icons" viewBox="0 0 16 16">
						<path d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z"/>
						<path d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117zM11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5zM4 1.934V15h6V1.077l-6 .857z"/>
					  </svg>
					  Profile
				</a>
				<a class="mt-2 mb-5 option-container" (click)="offcanvas.dismiss(); registerClicked()">
					<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="rgb(182,136,104)" class="bi bi-clipboard-plus margin-icons" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z"/>
						<path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
						<path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
					</svg>
					Register
				</a>

				
			</div> -->

			<div class="side-nav-body-payment py-4">
				<h3>Payment</h3>
				<div class="iconsContainer mt-2">
					<img style="height: 35px; width: 35px;" src="../../assets/img/payment_icons/venmoicon.png">
					<img src="../../assets/img/payment_icons/applepayicon.png">
					<img style="height: 35px; width: 35px;" src="../../assets/img/payment_icons/paypalicon.png">
					<div class="moneyIconContainer">
						<img style="height: 35px; width: 35px;" src="../../assets/img/payment_icons/moneyicon.png">
						<div>or</div>
						<img style="height: 35px; width: 35px;" src="../../assets/img/payment_icons/checkicon.png">
					</div>
				</div>
			</div>

				<div class="side-nav-body-contact py-4">
					<h3>Contact</h3>
					<div class="contactContainer mt-2">
						<div class="contacts">
							<div>Jay</div>
							<div>(954)-464-1568</div>
						</div>
						<div class="contacts">
							<div>Josh</div>
							<div>(386)-365-6869</div>
						</div>
					</div>
					
				</div>
			
				<div class="side-nav-footer mt-3">
					<h3 class="mt-2">Social</h3>
					<div class="side-nav-footer-icons">
						<a href="https://www.facebook.com/ShoppingTally/">
							<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#4267B2" class="bi bi-facebook" viewBox="0 0 16 16">
								<path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
							</svg>
						</a>
						<a href="https://www.instagram.com/shopping.tally/">
							<img src="../../assets/img/instagramLogo.png" height="40px" width="40px">
						</a>
					</div>
					
					
				</div>
			
		</div>
		
		
		
	</div>
</ng-template>



<!-- <button class="btn btn-lg btn-outline-primary mb-2 mt-2 me-2" >Right position</button> -->
<div class="nav-container">
	
	<div class="left-nav">
		<div class="showLoginRegister">
			
			
			<button color="success" id="registerButton" mat-raised-button class="margin-icons" (click)="registerClicked()">
				Register
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
					<path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
				</svg>
			</button>
			<a *ngIf="showLogin" id="loginButton" class="showLoginRegister" (click)="loginClicked()">
				<button color="success" mat-raised-button class="margin-icons">
					Login
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
					  <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
					</svg>
				</button>
			</a>
			<a routerLink="/profile" *ngIf="!showLogin">
				<button mat-raised-button color="success" class="margin-icons" (click)="loadUser()">
					Profile
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
					  <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
					</svg>
				</button>
			</a>
			
		</div>
		
		
	</div>
	<div class="iconContainer" *ngIf=cartVisibility>
		<img class="cartIcon" src="../../assets/img/cartIcon.png" (click)="cartClicked()">
		<div class="cartItemCounter">{{cartItemCounter}}</div>
	</div>
	
	<div class="menuContainer" (click)="openEnd(content)">
		<div class="line l1"></div>
		<div class="line l2"></div>
		<div class="line l3"></div>
		
	</div>
	
</div>

<router-outlet></router-outlet>

